<template>
  <section class="container my-24 my-lg-32 evraz">
    <div class="row">
      <header class="col">
        <h2>
          Личный кабинет для взаимодействия с наставником в рамках программы ТОП-300 4 поток
        </h2>
      </header>
    </div>

    <div class="row mt-24">
      <div class="col">
        <v-sheet
            outlined
            elevation="10"
            class="py-16 py-lg-32 pl-0 pl-lg-16 pr-16 pr-lg-32"
        >
          <div class="row">
            <div class="col-12 col-md-auto">
              <div class="row">
                <div class="col-4 col-md-auto">
                  <div class="evraz-avatar">
                    <img
                        :alt="info.name"
                        :src="info.img"
                        class="evraz-avatar__img"
                    />
                  </div>
                </div>
                <div class="col d-md-none">
                  <p
                      v-html="info.name"
                      class="h2 text-bold text-color-primary"
                  />
                  <v-divider/>
                </div>
              </div>
            </div>

            <div class="col pl-24 pl-md-16">
              <div class="row d-none d-md-flex">
                <div class="col">
                  <p
                      v-html="info.name"
                      class="h2 text-bold text-color-primary"
                  />
                  <v-divider/>
                </div>
              </div>

              <div class="row">
                <div
                    v-for="(item, index) in info.other"
                    :key="item.code"
                    :class="{'col-lg-6': index !== 0}"
                    class="col-12 mt-8 mt-lg-16"
                >
                  <p class="mb-4 text-color-default text-size-14">{{ item.title }}</p>
                  <p class="mb-0">{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </v-sheet>

        <v-divider
            style="border-color: #999999"
            class="my-24"
        />

        <v-expansion-panels flat>
          <template
              v-for="(panel, i) in panels"
          >
            <v-expansion-panel
                :key="panel.id"
            >
              <v-expansion-panel-header no-icon>
                <template v-slot:default="{ open }">
                  <div class="row align-items-center mb-24">
                    <div class="col col-md-auto">
                      <h3>{{ panel.title }}</h3>
                    </div>

                    <div class="col-auto">
                      <v-icon
                          name="evraz-chevron"
                          sprite-name="evraz"
                          fill="primary"
                          size="16"
                          :class="{'a-flip-y_active': !open}"
                          class="a-flip-y mr-4"
                      />
                    </div>
                  </div>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="row">
                  <div
                      v-for="item in panel.items"
                      :key="item.id"
                      :class="item.classes"
                      class="col-12 mb-24"
                  >
                    <v-sheet
                        outlined
                        elevation="10"
                        class="h-100"
                    >
                      <div
                          :class="{
                      'bg-color-default': item.header.type === 'fill',
                      'text-white': item.header.type === 'fill'
                    }"
                          class="p-16"
                      >
                        <div class="row">
                          <div class="col">
                            <b>{{ item.header.text }}</b>
                          </div>
                          <div
                              v-if="item.header.icon"
                              class="col-auto"
                          >
                            <v-icon
                                :name="`evraz-${item.header.icon}`"
                                sprite-name="evraz"
                                fill="dark"
                            />
                          </div>
                        </div>

                      </div>

                      <v-divider
                          v-if="!item.header.type"
                          class="mx-16"
                      />

                      <div
                          v-html="item.html"
                          class="p-16"
                      />
                    </v-sheet>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-divider
                :key="`${panel.id}-divider`"
                :theme="i === 0 ? 'evraz-1' : null"
                :style="i === panels.length - 1 ? {'borderColor': '#999999'} : null"
                class="mb-18"
            />
          </template>

        </v-expansion-panels>

        <div class="row mb-24">
          <div class="col col-md-auto h3">
            Добавить встречу
          </div>

          <div class="col-auto col-lg">
            <v-icon
                name="plus"
                fill="primary"
            />
          </div>
        </div>

        <v-divider
            theme="evraz-2"
            class="mb-40"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VIcon,
  VSheet
} from '@components/base'

export default {
  name: 'SEvrazLK',

  components: {
    VIcon,
    VDivider,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VSheet
  },

  data () {
    return {
      info: {
        name: 'Ческидов<br/>Александр Александрович',
        img: require('@assets/images/verstka/evraz/user1.jpg'),
        other: [
          { id: 1, title: 'Функция:', text: 'Начальник отдела сопровождения проектов ЕВРАЗ НТМК' },
          { id: 2, title: 'Предприятие:', text: 'ЕВРАЗ НТМК' },
          { id: 3, title: 'Подразделение:', text: 'Дивизион Урал' },
          { id: 4, title: 'Стаж работы в текущей должности:', text: 'с 24 марта 2021 г. (6 месяцев)' },
          { id: 5, title: 'Возраст:', text: '36 лет' }
        ]
      },
      panels: [
        {
          id: 0,
          title: 'Бизнес-цели и цели развития',
          items: [
            {
              id: 1,
              classes: 'col-lg-6',
              header: {
                icon: null,
                text: 'Ключевые результаты за 2020 г. (достижения)',
                type: null
              },
              html: `
            <ol>
                <li>Разработка форм реестра проектов ДУ для ЕДЦ (вкладка CAPEX);</li>
                <li>Разработка упрощенной схемы и модели расчета малых инвестиционный проектов для СТТР (дебюрократизация);</li>
                <li>Совместно с МП проработан проект точки роста с NPV > 100m$ (МНЛЗ-1);</li>
                <li>Опыт управления отделом оценки и управления портфелем инвестиций (с 01.08.2020).</li>
            </ol>
            `
            },
            {
              id: 2,
              classes: 'col-lg-6',
              header: {
                icon: null,
                text: 'Текущие проекты / задачи в зоне ответственности',
                type: null
              },
              html: `
            <ol>
                <li>Внедрение системы упрощенного управления малыми проектами (CAPEX <1m$) на площадке ЕНТМК. Последующий шаг – повышение лимита по CAPEX для данных проектов  до 3m$ (Утверждение на ИК);</li>
                <li>Разработка и утверждение программы развития Доменного, Конверторного цеха, а так же ЭСПЦ на ЕВРАЗ НТМК;</li>
                <li>Повышение точности оценки сроков, бюджета и границ для проектов на фазах Concept и Options.</li>
            </ol>
            `
            },
            {
              id: 3,
              classes: 'col-lg-6',
              header: {
                icon: null,
                text: 'Цель развития:',
                type: null
              },
              html: 'В 2022 году увеличить объем инициатив утвержденных в качестве инвест.проектов ЕВРАЗ НТМК на 20% (без потери качества) за счет развития персонала (СПР: диалог о развитии/целях, делегирование, обратная связь) и дебюрократизации процессов.'
            },
            {
              id: 4,
              classes: 'col-lg-6',
              header: {
                icon: null,
                text: 'Запрос к наставнику:',
                type: null
              },
              html: `
            <ol>
                <li>Опыт в использовании СПР (обратная связь, диалог о развитии/целях, делегирование). Совет в конкретных кейсах.</li>
                <li>Синхронизация вектора проработки инвестиционных инициатив и мероприятий дебюрократизации со стратегическим видением высшего руководства.</li>
            </ol>
            `
            },
            {
              id: 5,
              classes: 'col-lg-6',
              header: {
                icon: null,
                text: 'Оценка потенциала PIF',
                type: 'fill'
              },
              html: `
            <table class="evraz-table">
                <tr>
                    <td style="width: 100%">Общий балл (% / A, B, C)</td>
                    <td class="text-nowrap text-right">A, 99%</td>
                </tr>
                <tr>
                    <td>Системное мышление</td>
                    <td class="text-nowrap text-right">95</td>
                </tr>
                <tr>
                    <td>Открытость к переменам</td>
                    <td class="text-nowrap text-right">87</td>
                </tr>
                <tr>
                    <td>Коммуникации</td>
                    <td class="text-nowrap text-right">59</td>
                </tr>
                <tr>
                    <td>Результативность</td>
                    <td class="text-nowrap text-right">59</td>
                </tr>
            </table>
            `
            },
            {
              id: 6,
              classes: 'col-lg-6',
              header: {
                icon: null,
                text: 'Корпоративные программы / награды',
                type: 'fill'
              },
              html: `<img src="${require('@assets/images/verstka/evraz/new-leaders-logo.png')}" alt=""/>`
            }
          ]
        },
        {
          id: 1,
          title: 'Встреча с наставником 1',
          items: [
            {
              id: 1,
              classes: 'col-lg-6',
              header: {
                icon: 'edit',
                text: 'Повестка',
                type: null
              },
              html: 'Рамки и место обучения кадров требуют определения и уточнения системы обучения кадров, соответствует насущным потребностям. Постоянное информационное обеспечение нашей деятельности представляет собой интересный эксперимент проверки модели развития. Повседневная практика показывает, что постоянный количественный рост и сфера нашей активности играет важную роль в формировании систем массового участия'
            },
            {
              id: 2,
              classes: 'col-lg-6',
              header: {
                icon: 'save',
                text: 'Итоги встречи',
                type: null
              },
              html: 'Разнообразный и богатый опыт постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание направлений прогрессивного развития. Равным образом пос'
            },
            {
              id: 3,
              classes: null,
              header: {
                icon: null,
                text: 'Комментарии наставника',
                type: 'fill'
              },
              html: 'Мы вынуждены отталкиваться от того, что сплочённость команды профессионалов позволяет выполнить важные задания по разработке поставленных обществом задач. В своём стремлении улучшить пользовательский опыт мы упускаем, что стремящиеся вытеснить традиционное производство, нанотехнологии превращены в посмешище, хотя само их существование приносит несомненную пользу обществу.'
            }
          ]
        },
        {
          id: 2,
          title: 'Встреча с наставником 2',
          items: [
            {
              id: 1,
              classes: 'col-lg-6',
              header: {
                icon: 'edit',
                text: 'Повестка',
                type: null
              },
              html: 'Рамки и место обучения кадров требуют определения и уточнения системы обучения кадров, соответствует насущным потребностям. Постоянное информационное обеспечение нашей деятельности представляет собой интересный эксперимент проверки модели развития. Повседневная практика показывает, что постоянный количественный рост и сфера нашей активности играет важную роль в формировании систем массового участия'
            },
            {
              id: 2,
              classes: 'col-lg-6',
              header: {
                icon: 'save',
                text: 'Итоги встречи',
                type: null
              },
              html: 'Разнообразный и богатый опыт постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание направлений прогрессивного развития. Равным образом пос'
            },
            {
              id: 3,
              classes: null,
              header: {
                icon: null,
                text: 'Комментарии наставника',
                type: 'fill'
              },
              html: 'Мы вынуждены отталкиваться от того, что сплочённость команды профессионалов позволяет выполнить важные задания по разработке поставленных обществом задач. В своём стремлении улучшить пользовательский опыт мы упускаем, что стремящиеся вытеснить традиционное производство, нанотехнологии превращены в посмешище, хотя само их существование приносит несомненную пользу обществу.'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "~@assets/styles/variables";
@import "~@assets/styles/tools";

.evraz {
  ul, ol {
    margin: 0;
  }
}

.evraz-table {
  width: 100%;

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid cl(border-1);
    }
  }

  td {
    padding: .5rem;
  }

  td:first-child {
    padding-left: 0;
  }

  td:last-child {
    padding-right: 0;
  }
}

.evraz-avatar {
  position: relative;
  display: flex;
  padding-left: 1rem;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 1rem;
    height: 3rem;
    background: #fcb53b;
    background: linear-gradient(0deg, #fcb53b 33%, #f57f29 33%, #f57f29 66%, #e32213 66%);
  }

  &__img {
    display: block;
    width: 100%;
    max-width: 11rem;
    height: auto;
    background-color: white;
  }
}
</style>
